<style lang="scss" scoped>
@media screen and (min-width: 769px) {
  .flex {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .gou {
    position: absolute;
    bottom: 5px;
    right: 5px;
  }

  // .Calendar-div:hover {
  //   // color: white !important;
  //   border: 1px blue solid;
  // }

  :deep(.el-calendar-day) {
    height: 40px;

    padding: 0 !important;
    // widht: 40px !important;
  }

  :deep(.el-calendar) {
    border-radius: 8px;
  }

  :deep(.el-calendar__body) {
    padding: 5px 30px 15px 30px !important;
  }

  :deep(.video-js) {
    object-fit: cover;
  }

  :deep(.el-drawer__header) {
    display: none;
  }

  .color_expired {
    background-color: #f5f5f5 !important;
    color: #cacaca !important;
  }

  .color_bai {
    background-color: #ffffff !important;
  }

  .color_appt {
    background: #10bd8e;
    color: #000000;
  }
  .borderActive {
    border: 1px solid blue !important;
  }

  .color_appt_none {
    background-color: #8b8b8b;
    color: #000000;
  }

  .color_draf {
    background-color: #409eff;
  }

  .calender-title {
    font-size: 14px;
    color: #0099a8;
    font-style: normal;
    text-align: left;
    line-height: 25px;
    letter-spacing: 0.03em;
    font-weight: 700;
    padding-left: 10px;
    // float: left;
  }

  .calender-title1 {
    font-size: 13px;
    color: #333;
    font-style: normal;
    text-align: left;
    line-height: 34px;
    letter-spacing: 0.03em;
    font-weight: 400;
    clear: both;
  }

  .mobile {
    display: none;
  }

  .schedule_btn {
    position: fixed;
    right: 0px;
    top: 100px;
  }

  .is-selected {
    color: #1989fa;
  }

  .is-disabled {
    pointer-events: none;
    color: #ccc;
  }

  .my-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .iconfont {
    font-size: 50px;
  }

  .schedule-header {
    width: 70%;
    margin: auto;
    font-size: 20px;

    // margin-bottom: 10px;
    .user {
      display: flex;
      margin-bottom: 20px;
    }

    hr {
      // margin: 10px 0;
      margin-top: 10px;
    }

    .div-line {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      .val {
        color: #3c3935;
        font-weight: 600;
      }
    }
  }

  .schedule-footer {
    h3 {
      height: 30px;
      line-height: 30px;
    }
  }

  .timeTable {
    border-top: 2px solid black;
    /* 上边框 */
    border-right: 2px solid black;
    /* 右边框 */
    border-left: 2px solid black;
    /* 左边框 */
    border-bottom: none;
    /* 去掉下边框 */
    border-radius: 2px;
    font-size: 18px;

    .header {
      display: flex;
      justify-content: space-between;
      font-weight: 600;
      padding: 0 10px;
      border-bottom: 2px solid black;
    }

    div {
      height: 35px;
      line-height: 35px;
      text-align: center;
    }
  }

  .make_btn {
    font-size: 15px;
    color: #fff;
    text-align: center;
    font-style: normal;
    height: 47.7px;
    line-height: 47.7px;
    letter-spacing: 0.03em;
    background-color: rgb(247, 134, 51);
    width: 100%;
    cursor: default;
    font-weight: 700;
    margin-top: 15px;
    padding: 0 1em;
    opacity: 1;
    box-sizing: border-box;
    // pointer-events: none;
  }

  .make_btn_active {
    background-color: rgb(247, 134, 51);
    pointer-events: auto !important;
  }

  .make_btn_disabled {
    background-color: #8b8b8b !important;
    pointer-events: none;
  }

  .schedule-end {
    margin: 10px 0;
    font-size: 14px;
    font-weight: 600;

    .line {
      height: 30px;
      display: flex;
    }

    .gray {
      height: 14px;
      width: 14px;
      background-color: #8b8b8b;
      border: 1px solid #3c3935;
      margin-right: 5px;
    }

    .green {
      height: 14px;
      width: 14px;
      background-color: #10bd8e;
      border: 1px solid #3c3935;
      margin-right: 5px;
    }
  }
}

/* 移动端样式 */
@media screen and (max-width: 768px) {
  // .mainImg {
  //   position: fixed;
  //   width: 100%;
  //   height: 100%;
  //   top: 0;
  // }

  // :deep(.el-button-group) {
  //   display: none;
  // }
  :deep(.el-calendar__header) {
    padding: 10px 0;
    justify-content: space-around;
  }

  .weight {
    font-weight: 700;
  }

  .color_expired {
    background-color: #f5f5f5 !important;
    color: #cacaca !important;
  }

  .color_bai {
    background-color: #ffffff !important;
  }

  .color_appt {
    background: #10bd8e;
    color: #000000;
  }
  .borderActive {
    border: 1px solid blue !important;
  }

  .color_appt_none {
    background-color: #8b8b8b;
    color: #000000;
  }

  .color_draf {
    background-color: #409eff;
  }

  .timeTable {
    border-top: 2px solid black;
    /* 上边框 */
    border-right: 2px solid black;
    /* 右边框 */
    border-left: 2px solid black;
    /* 左边框 */
    border-bottom: none;
    /* 去掉下边框 */
    border-radius: 2px;
    font-size: 18px;

    .header {
      display: flex;
      justify-content: space-between;
      font-weight: 600;
      padding: 0 10px;
      border-bottom: 1px solid black;
    }

    div {
      height: 50px;
      line-height: 50px;
      text-align: center;
    }
  }

  .make_btn {
    font-size: 17px;
    color: #fff;
    text-align: center;
    font-style: normal;
    line-height: 47.4px;
    letter-spacing: 0.03em;
    background-color: rgb(247, 134, 51);
    width: 100%;
    cursor: default;
    font-weight: 700;
    margin-top: 15px;
    padding: 0 1em;
    opacity: 1;
    box-sizing: border-box;
    // pointer-events: none;
  }

  .schedule-end {
    margin: 20px 0;
    font-size: 14px;
    font-weight: 600;

    .line {
      height: 30px;
      display: flex;
    }

    .gray {
      height: 14px;
      width: 14px;
      background-color: #8b8b8b;
      border: 1px solid #3c3935;
      margin-right: 5px;
    }

    .green {
      height: 14px;
      width: 14px;
      background-color: #10bd8e;
      border: 1px solid #3c3935;
      margin-right: 5px;
    }
  }

  .make_btn_active {
    background-color: rgb(247, 134, 51) !important;
    pointer-events: auto !important;
  }

  .make_btn_disabled {
    background-color: #8b8b8b !important;
    pointer-events: none;
  }

  .calender-title {
    font-size: 14px;
    color: #0099a8;
    font-style: normal;
    text-align: left;
    line-height: 36px;
    letter-spacing: 0.03em;
    font-weight: 700;
  }

  .calender-title1 {
    font-size: 13px;
    color: #333;
    font-style: normal;
    text-align: left;
    line-height: 34px;
    letter-spacing: 0.03em;
    font-weight: 400;
    clear: both;
  }

  .flex {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .weight {
    font-weight: 600;
  }

  .gou {
    position: absolute;
    bottom: 5px;
    right: 5px;
  }

  .is-disabled {
    pointer-events: none;
    color: #ccc;
  }

  .is-color-red {
    background-color: rgb(255, 192, 192) !important;
    color: #ccc;
  }

  .is-color-blue {
    background-color: rgb(173, 216, 230) !important;
    color: #ccc;
  }

  .is-color-wir {
    background-color: #ffffff !important;
    color: #ccc;
  }

  .is-color-yellow {
    background-color: rgb(255, 255, 192) !important;
    color: #ccc;
  }

  .is-color-gray {
    background-color: rgb(136, 136, 136) !important;
    color: #ccc;
  }

  :deep(.el-calendar-day) {
    height: 40px !important;
  }

  :deep(.el-calendar-day) {
    padding: 0 !important;
  }

  :deep(.video-js) {
    object-fit: cover;
  }

  :deep(.el-drawer__header) {
    display: none;
  }

  /* 修改poster图片大小 */
  video::poster {
    width: 100% !important;
    height: 100% !important;
  }

  :deep(.el-drawer__header) {
    margin-bottom: 0px;
  }

  :deep(.btt) {
    background-color: rgba(0, 0, 0, 0.65);
  }

  :deep(.vjs-poster) {
    height: 100vh;
    background-size: cover !important;
  }

  .pc {
    display: none;
  }

  .main {
    position: relative;
    width: 100%;
    height: 100vh;
    /* 设置高度为视口的高度，以覆盖整个屏幕 */
    color: #ffffff;
  }

  .video-top {
    width: 100%;
  }

  .video-container {
    width: 100%;
    height: 100%;
  }

  .videoAWS-poster {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* 让图片等比例缩放并填充整个容器 */
  }

  .UserImg {
    position: absolute;
    /* 使用绝对定位 */
    top: 0;
    /* 距离顶部的距离为0 */
    left: 0;
    /* 距离左侧的距离为0 */
    width: 100%;
    /* 宽度占满父元素 */
    height: 100%;
    /* 高度占满父元素 */
    // object-fit: cover; /* 根据需要适应父元素大小，可能会裁剪图片 */
  }

  .User-top {
    position: absolute;
    top: 20px;
    left: 30px;
  }

  .chah {
    position: absolute;
    top: 20px;
    right: 30px;
  }

  .w-50 {
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: 150px;
    height: 40px;
    line-height: 40px;
    color: #000000;
    background-color: yellow;
    text-align: center;
  }

  .mobileGift {
    position: absolute;
    bottom: 70px;
    right: 20px;
    padding: 5px;
    // background-color: white;
    background-color: rgba(0, 0, 0, 0.3);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #000000;

    p {
      height: 40px;
      line-height: 48px;
      text-align: center;
    }
  }

  .giftTitle {
    width: 100%;
    text-align: center;
    height: 40px;
    line-height: 40px;
    color: #fac53c;
  }

  .mobileCredits {
    position: absolute;
    bottom: 150px;
    right: 20px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.2);
    color: white;

    p {
      height: 40px;
      line-height: 48px;
      text-align: center;
    }
  }

  .mobileSet {
    position: absolute;
    bottom: 230px;
    right: 20px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.2);
    color: white;

    p {
      height: 40px;
      line-height: 45px;
      text-align: center;
    }
  }

  .mobileSetAfter {
    position: absolute;
    bottom: 230px;
    right: 20px;
    width: 40px;
    height: 250px;
    border-radius: 18px;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.2);
    color: white;
    text-align: center;

    p {
      height: 50px;
      line-height: 50px;
    }
  }

  .demo-image .block {
    // padding: 30px 0;
    text-align: center;
    // border-right: solid 1px var(--el-border-color);
    display: inline-block;
    width: 33%;
    box-sizing: border-box;
    vertical-align: top;
  }

  .demo-image .block:last-child {
    border-right: none;
    color: #ffffff;
  }

  .custom-gift {
    border-radius: 10%;
  }

  .mobileMainCointaner {
    background: linear-gradient(180deg, lightblue, #fff, #fff);
    position: absolute;
    // height: 100%;
    width: 100%;
    height: 100%;
    // overflow-y: auto !important;

    .breadcrumb {
      font-size: 12px;
      font-weight: 700;
      margin: 20px 10px;
    }

    .advisorCard {
      // border: 1px solid #eaeaea;
      border-radius: 20px;
      height: 100%;
      margin: 10px 10px 10px 10px;
      box-sizing: border-box;
      // overflow: hidden;

      .advisorImage {
        position: relative;
        width: 100%;

        img {
          max-height: 300px;
          object-fit: cover;
          width: 100%;
        }

        .advisorRatingContainer {
          align-items: center;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 10px;
          width: 100%;

          .advisorRating {
            align-items: center;
            background-color: #ffecbc;
            border-radius: 51px;
            font-size: 18px;
            justify-content: flex-start;
            padding: 3px 15px;
            position: absolute;
            top: 10px;
          }

          .favorite {
            position: absolute;
            right: 10px;
            top: 5px;
            z-index: 10;

            img {
              height: 35px;
              width: 35px;
            }
          }
        }

        .advisorNameContainer {
          background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.5) 50%);
          bottom: 7%;
          position: absolute;
          width: 100%;

          .advisorName {
            color: white;
            font-size: 26px;
            font-weight: 700;
            padding-left: 15px;
          }

          .advisorCategory {
            color: white;
            font-size: 18px;
            font-weight: 400;
            overflow: hidden;
            padding: 0 0 20px 15px;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .advisorStatus {
          align-items: center;
          border: 4px solid white;
          border-radius: 50px;
          bottom: 0%;
          color: white;
          display: flex;
          font-size: 14px;
          font-weight: 700;
          justify-content: center;
          left: 50%;
          padding: 5px 20px;
          position: absolute;
          text-align: center;
          transform: translateX(-50%);
        }

        .advisorStatus-online {
          @extend .advisorStatus;
          background-color: rgb(115, 201, 64);
        }

        .advisorStatus-live {
          @extend .advisorStatus;
          background-color: red;
        }

        .advisorStatus-busy {
          @extend .advisorStatus;
          background-color: #46a6ff;
        }

        .advisorStatus-offline {
          @extend .advisorStatus;
          background-color: #b6b6b6;
        }
      }

      .advisorChat {
        background-color: rgba(0, 0, 0, 0.04);
        border-radius: 6px;
        color: black;
        height: 25vh;
        overflow: hidden;
        overflow-y: scroll;
        position: relative;
        width: 100%;
      }

      .buttonContainer {
        align-items: center;
        display: flex;
        justify-content: center;

        .button {
          border: 1px solid lightgray;
          height: 40px;
          line-height: 40px;
          margin: 10px;
          text-align: center;
          width: 150px;
        }

        .chatButton {
          @extend .button;
          background-color: yellow;
          color: black;
        }

        .startVideoButton {
          @extend .button;
          background-color: #ffe400;
          color: #000000;
        }
      }
    }
  }
}
</style>

<template>
  <div>
    <!-- 头部 -->
    <div class="pc">
      <navbar :payStatus="payStatus" @closPay="buyCredits(false)" />
    </div>
    <!-- 中间内容 -->
    <div class="middle pc">
      <!-- <el-dialog v-model="scheduleDialog" :show-close="false">
        <template #header="{ close, titleId, titleClass }">
          <div class="my-header">
            <h4 :id="titleId" :class="titleClass">Consultant appointment schedule</h4>
            <el-button type="danger" @click="close">
              <el-icon class="el-icon--left"><CircleCloseFilled /></el-icon>
              Close
            </el-button>
          </div>
        </template>
        <div>
          <div class="schedule-header">
            <div v-if="roomDetail" class="user">
              <el-avatar
                shape="square"
                :size="100"
                :fit="fit"
                :src="
                  roomDetail.avatarImage
                    ? ImageUrl + roomDetail.avatarImage
                    : require('@/assets/new_performer_bg_experts_1024x768.png')
                "
              />
              <div style="margin-left: 50px">
                <div
                  style="
                    height: 50px;
                    line-height: 50px;
                    text-align: center;
                    width: 500px;
                  "
                >
                  Reservation number display
                </div>
                <div style="text-align: center">
                  <i class="icon-a-gerentouxiang2 iconfont"></i>
                  <i class="icon-touxiang iconfont"></i>
                  <i class="icon-a-gerentouxiang2 iconfont"></i>
                  <i class="icon-touxiang iconfont"></i>
                  <i class="icon-a-gerentouxiang2 iconfont"></i>
                  <i class="icon-touxiang iconfont"></i>
                  <i class="icon-a-gerentouxiang2 iconfont"></i>
                  <i class="icon-touxiang iconfont"></i>
                </div>
              </div>
            </div>
            <div class="div-line">
              <span>Date:</span> <span class="val">Theursday,February 1, 2024</span>
            </div>
            <div class="div-line">
              <span>hours:</span>
              <div class="val">
                <div>5:00 AM - 7:00 AM</div>
                <div>9:00AM - 12:00 PM</div>
              </div>
            </div>
            <div>(Available for callbacks)</div>
            <hr />
          </div>

          <div style="width: 70%; margin: auto">
            <el-calendar>
              <template #date-cell="{ data }">
                <p
                  :class="{
                    'is-selected': data.isSelected,
                  }"
                >
                  {{ data.day.split("-").slice(1).join("-") }}
                  {{ data.isSelected ? "✔️" : "" }}
                </p>
              </template>
            </el-calendar>

            <div class="schedule-footer">
              <h3>
                Choose a phone appointment below:
                <span class="weight" style="color: #3c3935">{{
                  timeSlotList.length || 0
                }}</span
                >
              </h3>
              <h3>Available Appointments:3</h3>

              <div class="timeTable">
                <div class="header">
                  <span
                    ><el-icon><Back /></el-icon
                  ></span>
                  <span> Friday, February 2, 2024 </span>
                  <span>
                    <el-icon><Right /></el-icon
                  ></span>
                </div>
                <div v-for="item in timetable" :key="item.id" class="header">
                  <span style="width: 30px"></span>
                  <span> {{ item.timeStart }} -{{ item.timeEnd }} </span>
                  <img
                    v-if="item.published"
                    style="width: 30px; height: 30px; margin-top: 9px"
                    src="../../assets/shixing.png"
                    alt=""
                  />
                  <img
                    v-else
                    style="width: 30px; height: 30px; margin-top: 9px"
                    src="../../assets/kongxing.png"
                    alt=""
                  />
                </div>
              </div>
              <div class="make_btn make_btn_active">MAKE APPOINTMENT</div>
            </div>

            <div class="schedule-end">
              <div class="line">
                <div class="green"></div>
                <div>phone appointments available</div>
              </div>
              <div class="line">
                <div class="gray"></div>
                <div>no appointments (available for callbacks)</div>
              </div>
            </div>
          </div>
        </div>
      </el-dialog> -->

      <div class="content">
        <div class="mid-video">
          <div class="video">
            <div class="video-top">
              <!-- <videoAWS :width="849" :height="479" :url="liveRoomUrl"></videoAWS> -->
              <iframe
                v-if="roomDetail"
                width="849"
                height="479"
                :src="roomDetail.profileUrl"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
            <div class="video-gif">
              <ul>
                <el-popover
                  placement="top"
                  effect="dark"
                  :width="200"
                  trigger="hover"
                  v-for="(item, index) in gifArr"
                  :key="index"
                >
                  <div class="gif-send">
                    <p class="send">{{ item.giftName }}</p>
                    <p>{{ item.coin }} {{ $t("Credits") }}</p>
                    <p>
                      <span v-if="userInfo?.coinBalance - Number(item.coin) < 0">{{
                        $t("youdonthave")
                      }}</span>
                      <el-button
                        style="padding: 0 6px"
                        color="#ffe400"
                        size="small"
                        @click="buyCredits(true)"
                      >
                        {{ $t("buyCredits") }}
                      </el-button>
                    </p>
                    <div
                      style="
                        display: flex;
                        justify-content: space-between;
                        margin-top: 8px;
                      "
                    >
                      <el-input-number v-model="num" :min="1" :max="100" />
                      <el-button
                        style="width: 50%"
                        color="#626aef"
                        @click="
                          userInfo?.coinBalance - Number(item.coin) < 0
                            ? ElMessage({
                                message: 'Not enough Credits, please purchase credits!',
                                type: 'error',
                              })
                            : postGiftRecord(item.id)
                        "
                      >
                        {{ $t("gift") }}
                      </el-button>
                    </div>
                  </div>
                  <template #reference>
                    <li class="moji-item">
                      <div class="img-wrap" v-show="item.image">
                        <img :src="ImageUrl + item.image" alt="" />
                      </div>
                    </li>
                  </template>
                </el-popover>
              </ul>
              <div class="gif-messg" @click="openStartVadio">
                Start Private Session ($<span style="font-size: 15px; font-weight: 700">{{
                  minutes ? minutes : 0
                }}</span
                >/min)
              </div>
              <div class="buy-thing" @click="openBuyCredits">
                Buy Credits
                <span v-if="getToken()"
                  >(Balance: $<span style="font-size: 15px; font-weight: 700"
                    >{{ store1.balance }} </span
                  >)</span
                >
              </div>
            </div>
          </div>
          <div class="chat-all">
            <div class="zhuanHuan">
              <el-icon
                v-if="!timeChartsFlag"
                @click="transformTimeChart"
                size="23"
                style="color: #0099a8"
              >
                <ChatDotRound />
              </el-icon>
              <el-icon
                v-else
                @click="transformTimeChart"
                size="23"
                style="color: #ffffff"
              >
                <Calendar />
              </el-icon>
            </div>
            <div class="chat-top" v-if="!timeChartsFlag && !isMobile">
              <div style="padding: 5px 10px">
                <div class="calender-title">APPOINTMENTS CALENDAR</div>
              </div>
              <div class="calendaBox">
                <calendar :userId="userId" :consultantId="consultantID"></calendar>
                <div style="padding: 0 20px">
                  <div>(Available for callbacks)</div>
                  <div class="schedule-end">
                    <div class="line">
                      <div class="green"></div>
                      <div>phone appointments available</div>
                    </div>
                    <div class="line">
                      <div class="gray"></div>
                      <div>no appointments (available for callbacks)</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="chat-messgae">
              <p>Welcome in!</p>
            </div> -->
            <div class="chat-mess" v-if="timeChartsFlag">
              <p>Welcome! Let's connect deeper in private!</p>
            </div>
            <div class="messages" v-if="timeChartsFlag">
              <ul id="msg-ul" ref="msglist">
                <li v-for="(item, index) in chatArr" :key="index">
                  <div style="color: bisque; transform: scale(0.8); margin-left: -35px">
                    <span v-if="item.type == 'gift'">{{
                      ProcessingTime(item.content.createTime)
                    }}</span>
                    <span v-else>{{ ProcessingTime(item.createTime) }}</span>
                  </div>
                  <div v-if="item.type == 'gift'">
                    <span style="display: flex; align-items: center">
                      {{ item.content.userName }}
                      {{ $t("presented") }}
                      {{ item.content.num }}
                      <img
                        style="width: 14px; height: 14px; border-radius: 50%"
                        :src="ImageUrl + item.content.image"
                        alt=""
                      />
                    </span>
                  </div>
                  <div v-else-if="item">
                    <span v-if="item.consultant">{{ item.consultant.nickname }} : </span>
                    <span v-if="item.user">{{ item.user.nickname }} : </span>
                    <span style="word-break: break-all; text-align: justify">{{
                      item.content
                    }}</span>
                  </div>
                </li>
              </ul>
              <div>
                <el-input
                  style="width: 83.5%; height: 37px"
                  v-model="input"
                  placeholder="Say Something"
                />
                <el-button style="height: 37px" color="yellow" @click="postRoomMsg"
                  >Send</el-button
                >
              </div>
            </div>
            <!-- <div class="send-text" v-if="(!data.payMember)">
              <div class="talk">
                <p>Say Something...</p>
              </div>
              <div class="messages">
                <ul id="msg-ul" ref="msglist">
                  <li v-for="(item, index) in chatArr" :key="index">
                    <div style="color: bisque; transform: scale(0.8); margin-left: -35px">
                      <span v-if="item.type == 'gift'">{{
                        ProcessingTime(ZTime(item.content.createTime))
                      }}</span>
                      <span v-else>{{ ProcessingTime(ZTime(item.createTime)) }}</span>
                    </div>
                    <div v-if="item.type == 'gift'">
                      <span style="display: flex; align-items: center">
                        {{ item.content.userName }}
                        {{ $t("presented") }}
                        {{ item.content.num }}
                        <img
                          style="width: 14px; height: 14px; border-radius: 50%"
                          :src="ImageUrl + item.content.image"
                          alt=""
                        />
                      </span>
                    </div>
                    <div v-else-if="item">
                      <span v-if="item.consultant"
                        >{{ item.consultant.nickname }} :
                      </span>
                      <span v-if="item.user">{{ item.user.nickname }} : </span>
                      <span style="word-break: break-all; text-align: justify">{{
                        item.content
                      }}</span>
                    </div>
                  </li>
                </ul>
              </div>
 
            </div> -->
          </div>
        </div>
        <div class="information" v-if="roomDetail">
          <div class="img">
            <img
              :src="
                roomDetail.avatarImage
                  ? ImageUrl + roomDetail.avatarImage
                  : require('@/assets/new_performer_bg_experts_1024x768.png')
              "
              alt=""
            />
          </div>
          <div class="infor-name">
            <p style="font-weight: 800">{{ roomDetail.nickname }}</p>
            <el-rate
              v-model="roomDetail.score"
              disabled
              show-score
              text-color="#ff9900"
              disabled-void-color="#228395"
              score-template="&nbsp;Ratings:&nbsp;{value}"
            />
            <div v-if="roomDetail.score === 0.0">No Score</div>
            <!-- <p style="font-size: 12px;"><el-rate v-model="roomDetail.score" /> {{ roomDetail.score }} </p> -->
          </div>
          <div class="reviews">Reviews: {{ roomDetail.sumComment }}</div>
        </div>
        <div class="infor-text" v-if="roomDetail">
          <div class="text">
            {{ roomDetail.description }}
          </div>
          <div class="classification">
            <h3 class="cation">Expertise:</h3>
            <div>
              <!-- leftType  rightType -->
              <p style="font-size: 12px; margin-top: 8px; padding-left: 30px">
                <span v-for="(item, index) in roomDetail.leftType" :key="index">
                  {{ item
                  }}{{ index == roomDetail.leftType.length - 1 ? "" : "," }} &nbsp;
                </span>
              </p>
            </div>
          </div>
          <div class="classification">
            <h3 class="cation">Topics:</h3>
            <div>
              <!-- leftType  rightType -->
              <p style="font-size: 12px; margin-top: 8px; padding-left: 30px">
                <span>
                  {{ roomDetail.rightType.join(", ") }}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div v-if="roomDetail" class="main-serve">
          <div
            v-if="roomDetail.aboutMyService !== null && roomDetail.aboutMyService !== ''"
          >
            <h3>About My Services</h3>
            <p>{{ roomDetail.aboutMyService }}</p>
          </div>
          <div
            v-if="roomDetail.qualifications !== null && roomDetail.qualifications !== ''"
          >
            <h3>Experience & Qualifications</h3>
            <p>{{ roomDetail.qualifications }}</p>
          </div>
          <div v-if="roomDetail.other !== null && roomDetail.other !== ''">
            <h3>Other</h3>
            <p>{{ roomDetail.other }}</p>
          </div>
        </div>
        <div v-if="roomDetail">
          <div v-if="roomDetail.images.length != 0" class="upImages">
            <div
              style="width: 196px; height: 196px; margin: 0px 4px 4px 0px"
              v-for="(item, index) in roomDetail.images"
              :key="index"
            >
              <el-image
                style="width: 100%; height: 100%"
                :src="ImageUrl + item.imageUrl"
                :fit="'cover'"
                :preview-src-list="[ImageUrl + item.imageUrl]"
              />
            </div>
          </div>
        </div>
        <div class="recommend">
          <!-- <ul>
                        <li v-for="(item, index) in  tuijianList " :key="index" @click="goDetailPage(item.id, item)">
                            <img :src="ImageUrl + item.listImage" alt="" />
                            <span class="'recomd-icon">
                                <svg-icon style="font-size: 18px;" icon="aixin"></svg-icon>
                            </span>
                            <div :class="item.status == '2' ? 'recomd-peple' : 'recomd-peple filtergly'">
                                <svg-icon icon="aixin"></svg-icon>
                                live
                            </div>
                            <div v-if="item.status == '2'" class="recomd-peple">
                                <svg-icon icon="zaixian"></svg-icon>
                                live
                            </div>
                        </li>
                    </ul> -->
        </div>

        <div class="tailLanguage">&nbsp;</div>
      </div>
    </div>
    <!-- <div class="pc schedule_btn">
      <el-button type="primary" @click="scheduleDialog = true">SCHEDULE</el-button>
    </div> -->

    <!-- 移动端 -->
    <div class="mobile">
      <!-- <mobileCredits ref="moRef" /> -->

      <!-- <teleport to="head">
       <navbar :payStatus="payStatus" ref="moRef"  @closPay="buyCredits(false)"  /> 
      </teleport> -->

      <navbar :payStatus="payStatus" ref="moRef" @closPay="buyCredits(false)" />

      <div class="mobileMainCointaner" v-if="roomDetail">
        <div class="breadcrumb">
          <span @click.stop="router.push('/onlineExpert')">Home</span>
          <span style="color: #9d9d9d"
            >&nbsp;&nbsp;&nbsp;>&nbsp;&nbsp;&nbsp;{{ roomDetail.nickname }}</span
          >
        </div>
        <div class="advisorCard">
          <div class="advisorImage">
            <img v-if="!roomDetail.profileUrl" :src="ImageUrl + roomDetail.listImage" />
            <iframe
              v-if="roomDetail.profileUrl"
              width="100%"
              height="300px"
              :src="roomDetail.profileUrl"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
            <div class="advisorRatingContainer">
              <div class="advisorRating">
                <svg
                  width="21"
                  height="19"
                  viewBox="0 0 21 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.44484 11.9755L4.28412 17.5429C4.27816 17.7509 4.38038 17.9484 4.55677 18.0708C4.73338 18.193 4.96209 18.2241 5.16806 18.1547L10.6768 16.2887L16.1853 18.1547C16.2575 18.1791 16.3325 18.1911 16.4072 18.1911H16.4116C16.7774 18.1911 17.0739 17.9087 17.0739 17.5602C17.0739 17.5295 17.0717 17.4992 17.0673 17.4695L16.9087 11.9753L20.4739 7.56136C20.6072 7.39648 20.6463 7.17965 20.5789 6.98154C20.5114 6.78364 20.3456 6.63033 20.1363 6.57144L14.5282 4.99667L11.2232 0.402515C11.0996 0.230694 10.8951 0.128275 10.6768 0.128275C10.4585 0.128275 10.254 0.230905 10.1304 0.402515L6.82556 4.99667L1.21727 6.57144C1.00798 6.63033 0.84219 6.78364 0.774636 6.98154C0.707082 7.17944 0.746378 7.39648 0.87972 7.56136L4.44484 11.9755Z"
                    fill="#FFB800"
                  />
                </svg>
                <span style="margin-left: 8px">
                  <b>{{ roomDetail.score ? roomDetail.score.toFixed(1) : "0.0" }}</b>
                </span>
              </div>
              <div class="favorite">
                <img
                  v-if="roomDetail.collect == '0'"
                  src="@/assets/fabulous.png"
                  @click.stop="changeFollow(roomDetail, index)"
                />
                <img
                  v-if="roomDetail.collect == '1'"
                  src="@/assets/aixint.png"
                  @click.stop="changeFollowDel(roomDetail, index)"
                />
              </div>
            </div>
            <div class="advisorNameContainer">
              <div class="advisorName">
                <h4>{{ roomDetail.nickname }}</h4>
              </div>
              <div class="advisorCategory">
                {{ roomDetail.categoryList.map((category) => category.name).join(", ") }}
              </div>
            </div>
            <div class="advisorStatus-online" v-if="roomDetail.status == '1'">ONLINE</div>
            <div class="advisorStatus-live" v-else-if="roomDetail.status == '2'">
              LIVE
            </div>
            <div class="advisorStatus-busy" v-else-if="roomDetail.status == '3'">
              BUSY
            </div>
            <div class="advisorStatus-offline" v-else-if="roomDetail.status == '0'">
              OFFLINE
            </div>
          </div>
          <div class="advisorChat" ref="advisorChatRef">
            <div v-for="(item, index) in chatArr" :key="index">
              <div v-if="item.type == 'gift'">
                <div style="display: flex; align-items: center">
                  <span
                    ><el-avatar
                      :size="23"
                      :src="
                        item.user?.avatar
                          ? ImageUrl + item.user.avatar
                          : require('@/assets/new_performer_bg_experts_1024x768.png')
                      "
                  /></span>
                  <span>{{ item.content.userName }}</span>
                  <span>{{ $t("presented") }}</span>
                  <span> {{ item.content.num }}</span>
                  <span
                    ><img
                      style="width: 14px; height: 14px; border-radius: 50%"
                      :src="ImageUrl + item.content.image"
                      alt=""
                  /></span>
                </div>
              </div>
              <div
                style="height: 25px; line-height: 25px; margin: 5px 0; display: flex"
                v-else-if="item"
              >
                <span style="margin-right: 5px; margin-bottom: 10px"
                  ><el-avatar
                    :size="23"
                    :src="
                      item.user?.avatar
                        ? ImageUrl + item.user.avatar
                        : require('@/assets/new_performer_bg_experts_1024x768.png')
                    "
                /></span>
                <span style="font-weight: 500" v-if="item.consultant"
                  >{{ item.consultant.nickname }}:&nbsp;</span
                >
                <span style="font-weight: 500" v-if="item.user"
                  >{{ item.user.nickname }}:&nbsp;</span
                >
                <span style="word-break: break-all; text-align: justify">{{
                  item.content
                }}</span>
              </div>
            </div>
          </div>
          <div class="buttonContainer">
            <div class="chatButton" @click="mobileTextFn">Say Something</div>
            <div class="startVideoButton" @click="openStartVadio">Start Session</div>
          </div>
          <div>
            <div style="padding: 10px 20px">
              <div class="calender-title">APPOINTMENTS CALENDAR</div>
            </div>

            <calendar :userId="userId" :consultantId="consultantID"></calendar>
            <div style="padding: 0 20px">
              <div>(Available for callbacks)</div>
              <div class="schedule-end">
                <div class="line">
                  <div class="green"></div>
                  <div>phone appointments available</div>
                </div>
                <div class="line">
                  <div class="gray"></div>
                  <div>no appointments (available for callbacks)</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div  @click.stop="allClick"  class="main">
        <img :src="playerOptions.poster" alt="Video Poster" />
        <videoAWS
          :width="mobileSpWidth"
          :height="mobileSpHeight"
          :url="liveRoomUrl"
          :poster="playerOptions.poster"
        ></videoAWS>
      </div> -->

      <!-- <div class="User-top" style="display: flex" v-if="roomDetail">
        <el-avatar
          :src="
            roomDetail.avatarImage
              ? ImageUrl + roomDetail.avatarImage
              : require('@/assets/new_performer_bg_experts_1024x768.png')
          "
        />
        <div style="margin-left: 10px">
          <p style="font-weight: 800; color: #fff">{{ roomDetail.nickname }}</p>
          <p class="life-online" v-if="roomDetail.status == '1'">Online</p>
          <p class="life-live" v-else-if="roomDetail.status == '2'">Live</p>
          <p class="life-busy" v-else-if="roomDetail.status == '3'">Busy</p>
          <p class="life-offline" v-else-if="roomDetail.status == '0'">Offline</p>
          <p style="font-size: 12px;"><el-rate v-model="roomDetail.score" /> {{ roomDetail.score }} </p>
        </div>
      </div> -->
      <!-- <svg-icon
        class="chah"
        style="cursor: pointer; font-size: 30px"
        icon="chahao"
        @click="router.push('/onlineExpert')"
      ></svg-icon> -->

      <!-- 礼物 -->
      <!-- <div class="mobileGift" @click="sendGift">
        <p><img style="width:20px;height:20px" src="@/assets/gift01.png" alt=""></p>
      </div> -->

      <!-- 点数  -->
      <div class="mobileCredits" @click="buyCredits(true)">
        <p><img style="width: 20px; height: 20px" src="@/assets/pay01.png" alt="" /></p>
      </div>

      <!-- 设置  -->
      <div class="mobileSet" v-if="!mobileSetAfter" @click="mobileSet">
        <p>
          <el-icon><More /></el-icon>
        </p>
      </div>

      <!-- 点击设置后-->
      <div v-if="mobileSetAfter" class="mobileSetAfter">
        <p @click="mobileUserInfo = true">
          <el-icon>
            <User />
          </el-icon>
        </p>
        <p @click="mobilePhotos = true">
          <el-icon>
            <PictureFilled />
          </el-icon>
        </p>
        <p>
          <img
            style="width: 20px; height: 20px"
            v-if="roomDetail.collect == '0'"
            src="@/assets/fabulous.png"
            @click.stop="changeFollow(roomDetail)"
          />
          <img
            style="width: 20px; height: 20px"
            v-if="roomDetail.collect == '1'"
            src="@/assets/aixint.png"
            @click.stop="changeFollowDel(roomDetail)"
          />
        </p>
        <p @click="gotoMsgDetail">
          <el-icon>
            <ChatDotSquare />
          </el-icon>
        </p>
        <p>
          <svg-icon
            style="cursor: pointer; font-size: 20px"
            icon="chahao"
            @click="mobileSetAfter = false"
          ></svg-icon>
        </p>
      </div>

      <!-- <div class="UserImg">
        <img style=" width:100%; height:100%; object-fit:cover" :src="playerOptions.poster" alt="" />
      </div> -->

      <!-- 顾问相册 -->
      <el-drawer
        size="93%"
        v-model="mobilePhotos"
        title="Gallery"
        direction="btt"
        :before-close="handleClose"
      >
        <div v-if="roomDetail">
          <div v-if="roomDetail.images.length !== 0" class="upImages">
            <div
              style="
                width: 48%;
                height: 196px;
                display: flex;
                margin: 4px 2px;
                justify-content: space-between;
              "
              v-for="(item, index) in roomDetail.images"
              :key="index"
            >
              <el-image
                style="width: 100%; height: 100%"
                :src="ImageUrl + item.imageUrl"
                :fit="'cover'"
                :preview-src-list="[ImageUrl + item.imageUrl]"
              />
            </div>
          </div>
          <div v-else>
            <h2 style="color: red">Photo albums are empty</h2>
          </div>
        </div>
      </el-drawer>

      <!-- 顾问展示弹窗 -->
      <el-drawer
        size="93%"
        v-model="mobileUserInfo"
        title="About me"
        direction="btt"
        :before-close="handleClose"
      >
        <div
          style="
            color: #ffffff;
            width: 100%;
            word-break: break-word;
            word-wrap: break-word;
            white-space: pre-line;
          "
        >
          <div style="margin-bottom: 20px">
            <h3>About My Services</h3>
            <p
              v-if="
                roomDetail.aboutMyService !== null && roomDetail.aboutMyService !== ''
              "
            >
              {{ roomDetail.aboutMyService }}
            </p>
          </div>
          <div style="margin-bottom: 20px">
            <h3>Experience & Qualifications</h3>
            <p
              v-if="
                roomDetail.qualifications !== null && roomDetail.qualifications !== ''
              "
            >
              {{ roomDetail.qualifications }}
            </p>
          </div>
          <div style="margin-bottom: 20px">
            <h3>Other</h3>
            <p v-if="roomDetail.other !== null && roomDetail.other !== ''">
              {{ roomDetail.other }}
            </p>
          </div>
        </div>
      </el-drawer>

      <!-- 聊天弹窗 -->
      <el-drawer
        size="30%"
        v-model="liaotian"
        title="Scrolling"
        direction="btt"
        :before-close="handleClose"
      >
        <div style="margin-top: 30px">
          <el-input style="width: 80%" v-model="input" placeholder="Say Something" />
          <el-button color="yellow" @click="postRoomMsg">Send</el-button>
        </div>
      </el-drawer>
    </div>

    <div v-if="store1.isSide">
      <Sidebar />
    </div>

    <!-- 尾部 -->
    <div class="pc">
      <Footer></Footer>
    </div>

    <div class="comment" v-if="data.isRate == true">
      <h3 class="com-head">Leave a review of your anchor</h3>
      <div class="com-infor">
        <div class="infor-left">
          <img
            src="https://msimg0.doclercdn.com/ff268cab8d9fbae1ed7506f97496274f1e/e782ec2801826403642ec182210652db_glamour_445x250.webp?cno=856a"
            alt=""
            srcset=""
          />
          <p>name</p>
        </div>
        <div class="infor-right">
          <el-rate
            v-model="value"
            :texts="['oops', 'disappointed', 'normal', 'good', 'great']"
            show-text
          />
        </div>
      </div>
      <el-input
        v-model="data.commentext"
        :autosize="{ minRows: 5, maxRows: 4 }"
        type="textarea"
        placeholder="Please leave your comment"
      />
      <div class="com-submit" @click="saveComment">submit</div>
      <svg-icon class="chaIcon" icon="chahao" @click="data.isRate = false"></svg-icon>
    </div>
  </div>
</template>

<script setup>
import {
  PictureFilled,
  More,
  User,
  ChatDotSquare,
  WalletFilled,
  Ticket,
  Right,
  Back,
  Refresh,
  Calendar,
  ChatDotRound,
} from "@element-plus/icons-vue";
import calendar from "@/components/calendar/Scheduler_calendar.vue";
import Navbar from "@/layout/components/Navbar.vue";
import Footer from "../.././layout/components/Footer.vue";
import videoAWS from "../../components/videoAWS";
import { ElNotification, ElMessage } from "element-plus";
// import moment from "moment";
import {
  getCurrentInstance,
  onMounted,
  onBeforeUnmount,
  watch,
  computed,
  reactive,
  nextTick,
  ref,
  onBeforeMount,
} from "vue";
// import WebSocketClient from '../../utils/WebSocketClient'
import { page1Store } from "@/store/page1";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import request from "@/utils/request.js";
import { joinLive, getOnlinePeriod, setAppointment } from "@/api/detailPage.js";
import { tr } from "element-plus/es/locale";
import { getToken, getType } from "@/utils/auth";
import bus from "vue3-eventbus";
import { phoneStore } from "../../store/phone1";
import Sidebar from "@/layout/components/Sidebar/index.vue";
import { convertToAMPM, nowDay } from "@/components/timeTransform/index.js";
import { debounce } from "lodash";

const calendarData = ref([]);
const scheduleDialog = ref(false);
const advisorChatRef = ref();
const userId = ref(null);
const mobilePhotos = ref(false);
const mobileUserInfo = ref(false);
const mobileSetAfter = ref(false); //控制点击设置后的显隐
const moRef = ref(null);
const oneGift = ref({}); //单个礼物的详细信息
const isGift = ref(false);
const innerDrawer = ref(false);
const liaotian = ref(false);
const store1 = phoneStore();
const page1StoreDa = page1Store();
let webSocket = null;
const test = ref(false);
const route = useRoute();
const router = useRouter();
const store = useStore();
const ImageUrl = process.env.VUE_APP_BASE_ImageUrl;
const URL = process.env.VUE_APP_BASE_URL;
const payStatus = ref(false);
const minutes = ref(0);
const userInfo = computed(() => {
  return JSON.parse(store.state.userInfo);
});
let playerOptions = reactive({
  autoplay: false, //如果true,浏览器准备好时开始回放。
  controls: true, //控制条
  preload: "auto", //视频预加载
  muted: false, //默认情况下将会消除任何音频。
  loop: false, //导致视频一结束就重新开始。
  language: "zh-CN",
  aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
  fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
  sources: [
    {
      withCredentials: false,
      type: "application/x-mpegURL",
      src:
        "https://14fa7516a489.us-east-1.playback.live-video.net/api/video/v1/us-east-1.820887416855.channel.UhT1KRKCdv8Z.m3u8",
    },
  ],
  poster: "", //你的封面地址
  notSupportedMessage: "Not yet on the air", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
  controlBar: {
    timeDivider: true, // 当前时间和持续时间的分隔符
    durationDisplay: true, // 显示持续时间
    remainingTimeDisplay: false, // 是否显示剩余时间功能
    fullscreenToggle: true, // 是否显示全屏按钮
  },
});
let webSocketMsg = computed(() => {
  return page1StoreDa.webSocketMsg;
});
const msglist = ref();
const num = ref(1);
const input = ref("");
const consultantID = ref(null);
const roomDetail = ref(null);
const roomName = ref("");
const status = ref("");
const liveRoomUrl = ref("");
const roomInfo = computed(() => {
  return JSON.parse(store.state.roomObj);
});
// const socketStatus = computed(() => { return JSON.parse(store.state.socketStatus) })
const data = reactive({
  name: "Loverreturner",
  payMember: false,
  talksible: false,
  isRate: false,
  commentext: "",
});
const chatArr = ref([]);
const gifArr = ref([]);
const tuijianList = ref([]);

const mobileSpWidth = ref(
  window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
);
const mobileSpHeight = ref(
  window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight
);
const timeChartsFlag = ref(false);

const transformTimeChart = () => {
  timeChartsFlag.value = !timeChartsFlag.value;
};

const changeFollow = async (item) => {
  console.log(item, "11");
  if (!getToken() || getType() == "1") {
    ElMessage({ message: "Please login before adding favorites!", type: "warning" });
    bus.emit("joinOnLogin", true);
    return;
  }
  // let userInfo = window.localStorage.getItem("")
  let data = { userId: userId.value, consultantId: item.id };
  const { code } = await request.post("/system/follower/add", data);
  if (code == 200) {
    item.collect = "1";
    ElNotification({
      dangerouslyUseHTMLString: true,
      message: `<div>Added to favorites!</div>`,
      position: "bottom-right",
    });
  }
};

const changeFollowDel = async (item) => {
  if (!getToken() || getType() == "1") {
    ElMessage({ message: "Please login before adding favorites!", type: "warning" });
    bus.emit("joinOnLogin", true);
    return;
  }
  // let userInfo = window.localStorage.getItem("")
  let data = { userId: userId.value, consultantId: item.id };
  const { code } = await request.post("/system/follower/cancel", data);
  if (code == 200) {
    item.collect = "0";
    ElNotification({
      dangerouslyUseHTMLString: true,
      message: `<div>Removed from favorites!</div>`,
      position: "bottom-right",
    });
  }
};

//去聊天详情页面手机端
const gotoMsgDetail = () => {
  if (getType() == "1" || !getToken()) {
    bus.emit("LoginShow", true);
    return;
  }
  console.log(roomDetail.value);
  router.push({
    path: "/msgDetail/" + roomDetail.value.id,
    query: roomDetail.value,
  });
};

const mobileSet = () => {
  mobileSetAfter.value = true;
};

const sendGift = () => {
  isGift.value = true;
};

watch(mobileSpWidth, (n, o) => {
  mobileSpWidth.value = n;
});

watch(mobileSpHeight, (n, o) => {
  mobileSpHeight.value = n;
});

const mobileTextFn = () => {
  if (!getToken() || getType() == "1") {
    bus.emit("LoginShow", true);
    return;
  }

  console.log("111");
  liaotian.value = true;
};

//获取房间状态
const getLivestate = async (roomId) => {
  let params = {
    index: roomId,
  };
  let res = await joinLive(params);
  if (res.code == 200) {
    if (res.data && res.data.streamUrl) {
      liveRoomUrl.value = res.data.streamUrl;
      playerOptions.sources[0].src = res.data.streamUrl;
    }
  }
  test.value = true;
};

//时间转换函数
const ProcessingTime = (time) => {
  // const formattedTime = moment.utc(time).local().format("HH:mm:ss");
  // return formattedTime;
  const date = new Date(time);
  const month = date.getMonth() + 1; // 月份从0开始，所以需要加1
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const paddedHours = hours < 10 && hours >= 0 ? `0${hours}` : hours;
  const paddedMinutes = minutes < 10 && minutes >= 0 ? `0${minutes}` : minutes;
  const paddedSeconds = seconds < 10 && seconds >= 0 ? `0${seconds}` : seconds;
  return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
};

const ZTime = (time) => {
  const diff = new Date().getTimezoneOffset() * 60 * 1000;
  let timestamp = new Date(time).getTime() - diff;
  // 根据毫秒数构建 Date 对象
  let date = new Date(timestamp);
  // 格式化日期
  let dateTime = date.toLocaleString();
  dateTime = dateTime.substring();
  return dateTime;
};

watch(webSocketMsg, (newValue, oldValue) => {
  console.log("webSocketMsg", newValue);
  chatArr.value.push(newValue);
});

const getReading = async (id) => {
  const { code, data } = await request.get("/system/liveRoom/watch?consultantId=" + id);
};

// 获取顾问详情
async function faConsultantInfo(id) {
  const { code, data } = await request.get(
    "/system/faConsultant/info?consultantId=" + id
  );
  if (code == 200 && data) {
    minutes.value = data.perMinute;
    data.leftType = [];
    data.rightType = [];
    data.categoryList.forEach((item) => {
      if (item.pid == 1) {
        data.leftType.push(item.name);
      }
      if (item.pid == 2) {
        data.rightType.push(item.name);
      }
    });
    console.log(" roomDetail.value", data);
    roomDetail.value = data;
    playerOptions.poster = ImageUrl + data.listImage;
    roomName.value = data.liveRoom.roomName;
  }
}
async function postRoomMsg() {
  if (!getToken()) {
    bus.emit("LoginShow", true);
    return;
  }
  if (!window.localStorage.getItem("token")) {
    ElNotification({
      title: "Error",
      message: "Please login and try again!",
      type: "error",
    });
    return;
  }
  if (roomDetail.value.status == "0") {
    ElMessage({
      message: "The current expert is not online and cannot send messages!",
      type: "warning",
    });
    return;
  }
  if (!input.value) {
    return;
  }
  let data = {
    superId: roomName.value, //房间id
    content: input.value, //内容
    consultantId: String(consultantID.value),
  };
  console.log(data, "data参数");
  const { code, msg } = await request.post("/wom-chat-room/chatRoom/addChatInfo", data);
  if (code == 200) {
    input.value = "";
    liaotian.value = false;
    nextTick(() => {
      msgScroll();
    });
  } else {
    ElNotification({ title: "Error", message: msg, type: "error" });
  }
}

//评论完关闭按钮
const saveComment = () => {
  data.isRate = false;
};
// 列表滚动
function msgScroll() {
  if (msglist.value) {
    requestAnimationFrame(() => {
      msglist.value.scrollTop = msglist.value.scrollHeight;
    });
  }
  if (advisorChatRef.value) {
    requestAnimationFrame(() => {
      advisorChatRef.value.scrollTop = advisorChatRef.value.scrollHeight;
    });
  }
}

// 获取聊天室历史信息
async function getHistoryMsg(roomId) {
  let params = {
    roomId: roomId, //房间id
    userType: getType() == "1" ? 2 : 1,
    consultantId: String(consultantID.value),
    //consultantId
    // userId: '',     //用户id
  };
  const { data, code } = await request.get("/wom-chat-room/chatRoom/getById", { params });
  if (code == 200) {
    chatArr.value = chatArr.value.concat(data.chatInfoList);
    console.log(chatArr.value, "聊天消息");
  }

  nextTick(() => {
    msgScroll();
  });

  console.log(data, "获取聊天室历史信息");
}

// 获取礼物信息
async function getGifArr() {
  const { rows } = await request.get("/system/FaGift/list?status=1");
  gifArr.value = rows;
  console.log(rows);
}

// 赠送礼物
async function postGiftRecord(id) {
  if (!getToken()) {
    bus.emit("LoginShow", true);
    return;
  }
  let data = {
    id: id, // 礼物id
    num: num.value,
    roomIndex: roomName.value, // 房间号
    consultantId: Number(roomDetail.value.id),
  };
  const { code, msg } = await request.post("/system/giftRecord/add", data);
  if (code == 200) {
    ElNotification({ title: msg, message: "Gift given successfully!", type: "success" });
    setTimeout(() => {
      msgScroll();
    }, 200);
    innerDrawer.value = false;
    isGift.value = false;
  } else {
    ElNotification({ title: msg, message: "Gift giving failed!", type: "error" });
  }
}

// 打开购买积分
function buyCredits(e) {
  if (!getToken() || getType() == "1") {
    bus.emit("LoginShow", true);
    return;
  }
  console.log(payStatus.value, "....");
  payStatus.value = e;
  console.log(payStatus.value, "....1111");
}
async function getUpInfo() {
  const { data } = await request.get("/system/FaUser/info");
  console.log("res", data.id);
  userId.value = data.id;
}
async function joinRoom() {
  // 没有登录就不请求这个接口
  if (!getToken()) {
    return;
  }
  const { code } = await request.post("/system/liveRoom/join", {
    consultantId: consultantID.value,
  });
  if (code == 200) {
    store.commit("setSocketStatus", true);
    // ElMessage({ message: 'Joined successfully!', type: 'success', })
  } else {
    store.commit("setSocketStatus", false);
    // ElMessage({ message: 'Join failed!', type: 'error', })
  }
}
// 点击进入详情页面
const goDetailPage = (roomName, item) => {
  router.push("/detailPage/" + roomName);
  store.commit("setroomObj", JSON.stringify(item));
  document.body.scrollTop = document.documentElement.scrollTop = 0;
  location.reload();
};

async function openStartVadio() {
  if (!getToken() || getType() == "1") {
    bus.emit("LoginShow", true);
    return;
  }
  const { code, data } = await request.get(
    "/system/faConsultant/info?consultantId=" + roomDetail.value.id
  );
  if (code == 200) {
    const { id, listImage, nickname, liveRoom, freeMinutes, perMinute, status } = data;
    let obj = {
      id,
      listImage,
      nickname,
      liveRoom,
      freeMinutes,
      perMinute,
      status,
    };
    console.log(obj);
    store1.isPhoneShow = true;
    store1.CounselorMessage = obj;
  }
}

function openBuyCredits() {
  if (!getToken() || getType() == "1") {
    bus.emit("LoginShow", true);
    return;
  }
  bus.emit("openBuyCredits", true);
}
const isMobile = ref(null);
onMounted(() => {
  document.documentElement.scrollTop = 0;
  if (window.innerWidth > 768) {
    isMobile.value = false;
  } else {
    isMobile.value = true;
  }

  bus.on("getChartList", (e) => {
    msgScroll();
  });

  store1.isSide = false;
  consultantID.value = route.params.id;
  faConsultantInfo(consultantID.value);
  getReading(consultantID.value);
  setTimeout(() => {
    getHistoryMsg(roomName.value);
    getGifArr();
    getUpInfo();
    joinRoom(roomName.value);
    getLivestate(roomName.value);
  }, 500);
});
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 3px;
  /* 滚动条宽度 */
}

.p {
  line-height: 25px;
  font-size: 18px;
  margin-top: 5px;
  color: #000000;
  background-color: white;
  border-radius: 20px;
  padding: 10px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.03), 0 10px 20px rgba(0, 0, 0, 0.05);
  margin-bottom: 10px;
}

:deep(.el-rate__text) {
  // color: #fff;
}

:deep(.el-rate) {
  height: 18px;
}

:deep(.el-rate .el-rate__icon) {
  margin: 0;
}

.filtergly {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray !important;
}

.upImages {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  div:nth-child(6n + 0) {
    margin-right: 0 !important;
  }
}

.middle {
  width: 100%;
  background: #deeff3;

  .content {
    width: 1250px;
    // height: 1000px;
    margin: auto;

    .mid-video {
      width: 100%;
      display: flex;
      padding-top: 30px;

      .video {
        width: 850px;
        height: 517px;

        .video-top {
          width: 100%;
          height: 479px;
          background-color: black;

          video {
            min-width: 100%;
            // min-height: 100%; //不会因视频尺寸造成页面需要滚动
            // width: auto;
            // height: auto; //尺寸保持原视频大小
            z-index: -100; //z轴定位，小于0即可
            filter: grayscale(20%); //添加灰度蒙版，如果设定为100%则视频显示为黑白
          }
        }

        .video-player {
          background-color: red;
          // z-index: 100;
        }

        .video-gif {
          width: 100%;
          display: flex;
          border-radius: 5px;

          ul {
            width: 435px;
            height: 37px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #221c1c;

            .gif-send {
              font-size: 12px;
              padding: 0 18px;
              text-align: center;

              .send {
                color: #f3f5f8;
                font-size: 12px;
                margin: auto;
              }
            }

            .moji-item {
              .img-wrap:hover {
                background: #fff;
                box-shadow: 0 0 14px 2px #ffcc00;
              }

              .img-wrap {
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                img:hover {
                  box-shadow: 0 0 10px 0 #ffcc00;
                  background-color: #fff;
                }
              }
            }

            li {
              list-style: none;
              width: 23px;
              height: 25px;
              padding-top: 5px;
              cursor: pointer;
              margin-right: 10px;

              img {
                width: 20px;
                height: 20px;
                border-radius: 20px;
              }
            }
          }

          .gif-icon {
            cursor: pointer;
          }

          .gif-messg {
            width: 220px;
            height: 37px;
            background: #ffe400;
            line-height: 37px;
            text-align: center;
            font-size: 12px;
            cursor: pointer;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 0px 3px;
          }

          .buy-thing {
            width: 180px;
            height: 37px;
            background: #222222;
            line-height: 37px;
            text-align: center;
            font-size: 12px;
            color: #ffffff;
            cursor: pointer;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 0px 5px;
          }
        }
      }

      .chat-all {
        width: 400px;
        height: 517px;
        background: #02758a;
        position: relative;

        .zhuanHuan {
          position: absolute;
          right: 10px;
          top: 3px;
          z-index: 999;
        }

        .chat-top {
          position: relative;
          width: 400px;
          height: 517px;
          overflow: hidden;
          background-color: #deeff3;

          // img {
          //     width: 350px;
          //     height: 170px;
          // }
          .calendaBox {
            overflow-y: auto;
            height: 480px;
          }

          .chat-close {
            position: absolute;
            top: 8px;
            width: 100%;
            display: flex;
            justify-content: space-between;

            .close {
              background-color: #888282;
              padding: 4px;
              margin-right: 3px;
              font-size: 12px;
              cursor: pointer;
            }
          }
        }

        .chat-messgae {
          width: 100%;
          height: 30px;
          background-color: rgba(0, 0, 0, 0.3);
          font-size: 12px;
          line-height: 30px;
          color: #ffffff;

          p {
            margin-left: 6px;
          }
        }

        .chat-mess {
          width: 100%;
          height: 30px;
          background-color: rgba(0, 0, 0, 0.5);
          font-size: 12px;
          line-height: 30px;
          color: #ffffff;

          p {
            margin-left: 6px;
          }
        }

        .messages {
          width: 100%;
          height: 450px;
          background-color: rgba(0, 0, 0, 0.3);
          position: relative;

          .showTipMsg {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            color: #0fd6cc;
            font-size: 16px;
          }

          ul {
            // padding-top: 174px;
            height: 450px;
            overflow-y: auto;

            li {
              list-style: none;
              color: #ffffff;
              font-size: 12px;
              padding: 5px;
            }
          }
        }

        .talk {
          width: 100%;
          height: 37px;
          background: #ffffff;
          border-radius: 2px;
          font-size: 12px;
          line-height: 37px;
          cursor: pointer;

          p {
            margin-left: 4px;
          }
        }

        .send-text {
          height: 37px;

          :deep(.el-input__wrapper) {
            border-radius: 0;
            height: 37px;
          }

          :deep(.el-button) {
            border-radius: 0;
            height: 37px;
          }
        }
      }
    }

    .information {
      margin-top: 32px;
      width: 100%;
      display: flex;

      .img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 40px;
          height: 100%;
          object-fit: cover;
          // border-radius: 40px;
        }
      }

      .infor-name {
        width: 200px;
        margin-left: 16px;
        // color: #ffffff;
        p {
          width: 500px;
          // background-color:red;
        }
      }

      .reviews {
        width: 80%;
        line-height: 45px;
        text-align: right;
      }
    }

    .infor-text {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;
      grid-gap: 16px;
      grid-template-columns: repeat(3, 1fr);

      .text {
        width: 40%;
        font-size: 12px;
        color: #ccb8dc;
      }

      .classification {
        font-size: 18px;
        // color: #ffffff;
        width: 30%;

        .cation {
          border-left: solid 1px;
          padding-left: 30px;
          box-sizing: border-box;
          // color: #fff !important;
        }
      }

      .classification2 {
        font-size: 12px;
        // color: #ffffff;
        display: flex;

        .cation {
          border-left: solid 1px;
          padding-left: 30px;
          opacity: 0.4;
          margin-left: 100px;
        }
      }
    }

    .illustrate {
      // color: #ffffff;
      font-size: 22px;

      p {
        font-size: 18px;
        line-height: 25px;
        color: #ccb8dc;
      }
    }

    .selections {
      .demo-tabs > .el-tabs__content {
        padding: 32px;
        color: #f3f5f8;
        font-size: 32px;
        font-weight: 600;
      }

      :deep(.el-tabs__nav-wrap is-top.el-tabs__nav-wrap::after) {
        background-color: transparent;
      }
    }

    .recommend {
      margin-top: 40px;

      ul {
        display: flex;
        // justify-content: space-around;

        li {
          width: 300px;
          height: 218px;
          list-style: none;
          margin-right: 20px;
          position: relative;
          cursor: pointer;
          border-radius: 5px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
          }

          .recomd-icon {
            position: absolute;
            top: 10px;
            right: 8px;
            cursor: pointer;
          }

          .recomd-peple {
            position: absolute;
            color: #ffffff;
            background-color: #0fd6cc;
            top: 10px;
            left: 8px;
            font-size: 12px;
            padding: 3px;
            border-radius: 5px;
          }
        }
      }
    }

    .main-serve {
      // height: 650px;
      background: #fff;
      // padding: 5px;
      border-radius: 20px;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.03), 0 10px 20px rgba(0, 0, 0, 0.05);
      box-sizing: border-box;
      margin-bottom: 50px;

      h3 {
        padding: 10px;
        height: 40px;
        line-height: 40px;
        font-size: 22px;
        // border: 2px solid #eee;
      }

      p {
        padding: 5px 10px;
        width: 100%;
        font-size: 18px;
        // padding: 5px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        padding-bottom: 20px;
      }

      div {
        overflow: hidden;
        white-space: normal;
        word-wrap: break-word;
        white-space: pre-line;
      }
    }

    .tailLanguage {
      margin-top: 20px;
      padding-bottom: 50px;

      h3 {
        margin-bottom: 10px;
        padding-left: 5px;
        font-size: 22px;
      }

      h5 {
        font-size: 14px;
        color: #ffffff;
        line-height: 30px;
      }

      .changge {
        display: flex;
        justify-content: space-between;
        color: #ccb8dc;
        font-size: 12px;

        p {
          line-height: 20px;
          margin-right: 6px;
        }
      }
    }
  }
}

.comment {
  position: absolute;
  top: 10px;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 400px;
  height: 340px;
  background-color: #3a7e8a;
  padding: 10px;

  .com-head {
    line-height: 40px;
    color: #fff;
    text-align: center;
  }

  .com-infor {
    margin: 15px 60px;
    display: flex;

    .infor-left {
      margin-bottom: 20px;

      img {
        width: 50px;
        height: 50px;
        border-radius: 50px;
      }

      p {
        color: #fff;
      }
    }

    .infor-right {
      margin: 0 25px;
      margin-top: 15px;
    }
  }

  .com-submit {
    width: 100px;
    padding: 10px 15px;
    background-color: #ffcc00;
    margin: auto;
    margin-top: 20px;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
  }

  .chaIcon {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
}
</style>
